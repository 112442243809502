import * as React from "react"
import styled from "styled-components"
import { colors } from "../common/themeStyle"
import media from "styled-media-query"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { SEO } from "../components/seo"
import { useLocation } from "@reach/router"

const Contact = () => {
  const windowLocation = useLocation()
  return (
    <Layout>
      <SEO pageTitle="CONTACT" pagePath={windowLocation.pathname} />
      <ContactWrapper>
        <ContactImageWrapper>
          <StaticImage
            src="../images/kumachon_netabare.png"
            alt="kumachon netabare"
          />
        </ContactImageWrapper>

        <StyledNetabareHeadSentence>
          株式会社くまちょんは
          <LessThanMediumBreake />
          <GreaterThanMediumBreak />
          株式会社CLAVESの
          <LessThanMediumBreake />
          エイプリルフール企画です
        </StyledNetabareHeadSentence>
        <StyledNetabareSentence>
          株式会社くまちょんに興味を持っていただき
          <LessThanMediumBreake />
          ありがとうございます。
          <GreaterThanMediumBreak />
          <LessThanMediumBreake />
          株式会社CLAVESは遊びゴコロを大事に
          <LessThanMediumBreake />
          これからも精進してまいります。
        </StyledNetabareSentence>
        <StyledGoClavesSiteButton
          onClick={() => window.open("https://www.claves.co.jp/", "_blank")}
        >
          株式会社CLAVESのサイトへ
        </StyledGoClavesSiteButton>
      </ContactWrapper>
    </Layout>
  )
}

export default Contact

const ContactWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  max-width: 960px;
  padding: 0 1.0875rem 0;
`
const ContactImageWrapper = styled.div`
  margin: 0 auto;
  width: 300px;
  padding: 10px;
  ${media.lessThan("medium")`
  width: 200px;
  `}
`

const StyledNetabareHeadSentence = styled.h1`
  line-height: 1.3;
  font-weight: 400;
  font-size: 46px;
  text-align: center;
  margin-bottom: 30px;
  ${media.lessThan("medium")`
  font-size: 28px;
  `}
`

const StyledNetabareSentence = styled.p`
  height: 42px;
  left: 428px;
  top: 778px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.7;
  letter-spacing: 0.05em;
  text-align: center;
  color: ${colors.black};
  margin-bottom: 100px;
  ${media.lessThan("medium")`
  font-size: 16px;
  `}
`

const StyledGoClavesSiteButton = styled.button`
  text-decoration: none;
  width: 100%;
  max-width: 500px;
  height: 60px;
  border: 0px;
  margin: 0 auto;
  background-color: ${colors.yellow};
  font-size: 20px;
  font-weight: bold;
  line-height: 1.35;
  letter-spacing: 2px;
  color: ${colors.black};
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  margin-bottom: 100px;
  &:hover {
    cursor: pointer;
  }
  ${media.lessThan("medium")`
  margin-bottom: 70px;
  `}
`

const GreaterThanMediumBreak = styled.br`
  ${media.lessThan("medium")`
    display: none;
  `}
`
const LessThanMediumBreake = styled.br`
  ${media.greaterThan("medium")`
    display: none;
  `}
`
